import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ContentArea,
  FormDropdownField,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'

// eslint-disable-next-line
import rowImage from '../../queries/images/row-image'

const openPDF = (url: string): void => {
  try {
    if (typeof window !== `undefined`) {
      window.open(url, '_blank')
    }
  } catch (error) {
    console.log(error)
  }
}

const FinancialsPage = ({ data }) => {
    const annual_report_prefix: string = 'https://media.bscs.org/bscsmw/financials/annual-reports/'
    const years: string[] = ['2018', '2017', '2016', '2015', '2014', '2013', '2010', '2009', '2008']

    const [year, setYear] = useState<string>('2018')
    const [url, setUrl] = useState<string>(`${annual_report_prefix}bscs_2017_annual_report.pdf`)

  const handleSelection = (year: string): void => {
    document?.getElementById('download-pdf')?.focus()

    setYear(year)
    setUrl(`${annual_report_prefix}bscs_${year}_annual_report.pdf`)
  }

  return (
    <Layout>
      <SEO
        title="BSCS Science Learning financial documents"
        description="Browse BSCS’s annual reports, audit reports, and other financial statements."
        canonicalUrl="https://bscs.org/about/financials/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            About
          </BreadcrumbItem>
          <BreadcrumbItem>
            Financials
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>Financials</PageTitle>
        <TitleBorder />
        <div className="flex flex-wrap-reverse mt-3" style={{ marginBottom: '2rem' }}>
          <div className="pr-0 mt-3 md:mt-0 md:pr-2 w-full md:w-1/2">
            <p>
              In 2019, we prioritized our commitment to equity and social justice, received the largest research grant in our organization’s history, and continued addressing the critical need for high quality science instructional materials.
            </p>
            <a
              href="https://media.bscs.org/bscsmw/financials/annual-reports/bscs_2019_annual_report.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="mt-3" title='Download 2019 Annual Report PDF'>Download 2019 Annual Report PDF&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"/></sup></Button>
            </a>
          </div>
          <div className="w-full md:w-1/2">
            <Img
              fluid={data.ar2019.childImageSharp.fluid}
              alt="Young girl in an astronaut suit pointing at the moon."
            />
          </div>
        </div>
        <ContentArea size='lg'>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_science_learning_2018_133_audit_report.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS Science Learning 2018 133 Audit Report&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_science_learning_2018_financial_statements.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS Science Learning 2018 Financial Statements&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_2017_133_audit_report.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS 2017 133 Audit Report&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_2017_financial_statements.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS 2017 Financial Statements&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_bod_conflict_of_interest_and_confidentiality_statement.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS BoD Conflict of Interest and Confidentiality Statement&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_employee_conflict_of_interest_policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS Employee Conflict of Interest Policy&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
          <p>
            <a
              className="inline-anchor"
              href="https://media.bscs.org/bscsmw/financials/audits/bscs_indirect_cost_rate_agreement_2017.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              BSCS 2017 Indirect Cost Rate&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
            </a>
          </p>
        </ContentArea>
        <div>
          <h3>Download Additional Annual Reports</h3>
          <div className="w-24">
            <FormDropdownField
              dropdownItems={years}
              title='Select year'
              setValue={(value: string) => handleSelection(value)}
            />
          </div>

          <Button
            className="mt-3"
            id="download-pdf"
            onClick={() => openPDF(url)}
            title='Download Annual Report'
          >
            Download PDF&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon" /></sup>
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export default FinancialsPage

export const data = graphql`
  query {
    ar2019: file(relativePath: { eq: "financials/ar-2019.jpg" }) {
      ...rowImage
    }
  }
`

